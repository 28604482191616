import styled from "styled-components"

const LeftLogo = styled.div`
  width: 130px;
  height: 130px;
  display: block;
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;

  @media (max-width: 768px) {
    width: 90px;
    height: 90px;
    top: -30px;
  }
`

export default LeftLogo
