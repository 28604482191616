import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CenterLogo from "../components/CenterLogo"
import Logo from "../components/Logo"
import styled from "styled-components"
import LeftLogo from "../components/LeftLogo"
import PaddingLeftLogo from "../components/PaddingLeftLogo"
import Bg from "../components/Bg"
import H2 from "../components/H2"

import ContactFormer from "../components/ContactFormer"
const Relative = styled.div`
  position: relative;
`

const BgByj = styled.div`
  background-repeat: no-repeat;
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url("https://res.cloudinary.com/dh9k0znwt/image/upload/v1680073384/page%206%20Services/1920x680-3_xrombb.jpg");
  h1 {
    padding-top: 20vh;
  }
`

const ServicesPage = () => (
  <Layout>
    <Seo
      title="Beyond Jewelry Factory | Custom Made Jewelry | Prototyping, Production"
      description="Get high-quality, custom-made jewelry at Beyond factory. Our team of skilled craftsmen offer a wide range of services, including prototyping, mass production, and finishing. Contact us today to start your jewelry project."
    />
    <div className="flex flex-wrap py-16 bg-white xl:pt-32 pt-24">
      <div className="item w-full h-auto text-center md:py-9 py-3  2xl:pt-4">
        <BgByj></BgByj>
      </div>
    </div>
    <div className="flex flex-wrap pb-16 bg-white">
      <div className="item w-full h-auto text-center md:py-9 py-3">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="md:text-6xl text-4xl md:pt-0 pt-4">Services</H2>
        </Relative>
      </div>
    </div>

    <div className="container mx-auto">
      <div className="flex flex-wrap">
        <div className="item  w-full md:w-full lg:w-1/2 h-full sm:order-1  md:order-1 lg:order-1">
          <img
            className="object-contain md:object-scale-down f-r"
            alt="Customized jewelry packaging options,  including sustainable materials"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1673159824/AdobeStock_472860354_packaging_fkm0nv.jpg"
          />
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-auto order-4  md:order-4 lg:order-3 md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 lg:py-2"           >
              Packaging
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            We are offering customized packaging options based on your
            requirements. You are welcome to send your own packaging material to
            be used exclusively for you. When using plastic bags to protect our
            jewelry when shipping to you, we only use biodegradable plastic
            bags.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap py-16 bg-white">
        <div className="item  w-full md:w-full lg:w-1/2 h-auto order-4  md:order-4 lg:order-3 md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 lg:py-2"           >
              Labeling
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            Individual labeling options can be offered as well. You can either
            send your own labels or we can check if your labels can be sourced
            and printed in house.
          </p>
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-full order-3  md:order-3 lg:order-4">
          <img
            className="object-contain md:object-scale-down f-l"
            alt="a changer"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528318/page%206%20Services/AdobeStock_dc4gok.jpg"
          />
        </div>
        <div className="item w-full md:w-full lg:w-1/2 h-full order-5  md:order-5 lg:order-5">
          <img
            className="object-contain md:object-scale-down f-r"
            alt="a changer"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528319/page%206%20Services/Import_2_gpplr5.jpg"
          />
        </div>
        <div className="item w-full md:w-full lg:w-1/2 h-auto order-6  md:order-6 lg:order-6   md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 lg:py-2"           >
              Import
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            For customers who do not like to receive the goods from the factory
            in Thailand we can offer a special import service. Beyond Jewelry
            GmbH will be able to import your goods including shipping, customs
            clearance and insuring the goods. We will then invoice from our
            German office based in Pforzheim-Germany.
          </p>
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-auto order-8  md:order-8 lg:order-7 md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 lg:py-2"           >
              100% QC
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            Every single piece delivered to you passes through strict 100%
            quality control. We are happy to implement your special quality
            requirements.
          </p>
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-full order-7  md:order-7 lg:order-8">
          <img
            className="object-contain md:object-scale-down f-l"
            alt="a changer"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528319/page%206%20Services/Quality_control_fu8ye1.jpg"
          />
        </div>
      </div>
    </div>
    <Bg>
      <ContactFormer />
    </Bg>
  </Layout>
)

export default ServicesPage
