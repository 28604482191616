import styled from "styled-components"

const PaddingLeftLogo = styled.div`
  padding-top: 9vh;

  @media (max-width: 768px) {
    padding-top: 3vh;
  }
`

export default PaddingLeftLogo
